import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { USER_TYPE_SELLER } from '../../../util/types';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink, ExternalLink, FieldSelect } from '../../../components';

import productAgreementPdfLink from './product-agreement.pdf';
import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        userType,
        values,
      } = fieldRenderProps;

      const isSellerSignup = userType === USER_TYPE_SELLER;

      // email
      const emailLabel = intl.formatMessage({
        id: isSellerSignup ? 'SignupForm.businessEmailLabel' : 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const confirmPasswordPlaceholder = intl.formatMessage({
        id: 'SignupForm.confirmPasswordPlaceholder',
      });

      const confirmPasswordLabel = intl.formatMessage({
        id: 'SignupForm.confirmPasswordLabel',
      });

      const passwordsNotMatchingMessage = intl.formatMessage({
        id: 'SignupForm.passwordsNotMatchingMessage',
      });
      const confirmPasswordValidator = validators.valuesMatch(passwordsNotMatchingMessage, values.password)

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // business name
      const businessNameLabel = intl.formatMessage({
        id: 'SignupForm.businessNameLabel',
      });
      const buyerBusinessNameLabel = intl.formatMessage({
        id: 'SignupForm.buyerBusinessNameLabel',
      });
      const businessNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.businessNamePlaceholder',
      });
      const businessNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.businessNameRequired',
      });
      const businessNameRequired = validators.required(businessNameRequiredMessage);

      // business name
      const websiteLabel = intl.formatMessage({
        id: isSellerSignup ? 'SignupForm.businessWebsiteLabel' : 'SignupForm.websiteLabel',
      });
      const websitePlaceholder = intl.formatMessage({
        id: 'SignupForm.websitePlaceholder',
      });
      const websiteLinkInvalidMessage = intl.formatMessage({
        id: 'SignupForm.websiteLinkInvalid',
      });
      const websiteRequiredMessage = intl.formatMessage({
        id: 'SignupForm.websiteRequired',
      });

      const websiteLinkInvalid = validators.validURL(websiteLinkInvalidMessage);
      const websiteRequired = validators.required(websiteRequiredMessage);

      const addressLine1Label = intl.formatMessage({
        id: 'SignupForm.addressLine1Label',
      });

      const addressLine1Placeholder = intl.formatMessage({
        id: 'SignupForm.addressLine1Placeholder',
      });

      const addressLine1RequiredMessage = intl.formatMessage({
        id: 'SignupForm.addressLine1RequiredMessage',
      });

      const addressLine1Required = validators.required(addressLine1RequiredMessage);

      const addressLine2Label = intl.formatMessage({
        id: 'SignupForm.addressLine2Label',
      });

      const addressLine2Placeholder = intl.formatMessage({
        id: 'SignupForm.addressLine2Placeholder',
      });

      const cityLabel = intl.formatMessage({
        id: 'SignupForm.cityLabel',
      });

      const cityPlaceholder = intl.formatMessage({
        id: 'SignupForm.cityPlaceholder',
      });

      const cityRequiredMessage = intl.formatMessage({
        id: 'SignupForm.cityRequiredMessage',
      });

      const cityRequired = validators.required(cityRequiredMessage);

      const stateLabel = intl.formatMessage({
        id: 'SignupForm.stateLabel',
      });

      const statePlaceholder = intl.formatMessage({
        id: 'SignupForm.statePlaceholder',
      });

      const stateRequiredMessage = intl.formatMessage({
        id: 'SignupForm.stateRequiredMessage',
      });

      const stateRequired = validators.required(stateRequiredMessage);

      const postCodeLabel = intl.formatMessage({
        id: 'SignupForm.postCodeLabel',
      });

      const postCodePlaceholder = intl.formatMessage({
        id: 'SignupForm.postCodePlaceholder',
      });

      const postCodeRequiredMessage = intl.formatMessage({
        id: 'SignupForm.postCodeRequiredMessage',
      });

      const postCodeRequired = validators.required(postCodeRequiredMessage);

      const countryLabel = intl.formatMessage({
        id: 'SignupForm.countryLabel',
      });

      const countryPlaceholder = intl.formatMessage({
        id: 'SignupForm.countryPlaceholder',
      });

      const countryRequiredMessage = intl.formatMessage({
        id: 'SignupForm.countryRequiredMessage',
      });

      const countryRequired = validators.required(countryRequiredMessage);

      const phoneNumberLabel = intl.formatMessage({
        id: 'SignupForm.phoneNumberLabel',
      });
      const phoneNumberPlaceholder = intl.formatMessage({
        id: 'SignupForm.phoneNumberPlaceholder',
      });

      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'SignupForm.phoneNumberRequiredMessage',
      });

      const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);

      const minimumOrderLabel = intl.formatMessage({
        id: 'SignupForm.minimumOrderLabel',
      });
      const minimumOrderPlaceholder = intl.formatMessage({
        id: 'SignupForm.minimumOrderPlaceholder',
      });

      const minimumOrderRequiredMessage = intl.formatMessage({
        id: 'SignupForm.minimumOrderRequiredMessage',
      });

      const minimumOrderRequired = validators.required(minimumOrderRequiredMessage);

      const numberOfProductsLabel = intl.formatMessage({
        id: 'SignupForm.numberOfProductsLabel',
      });
      const numberOfProductsPlaceholder = intl.formatMessage({
        id: 'SignupForm.numberOfProductsPlaceholder',
      });

      const numberOfProductsRequiredMessage = intl.formatMessage({
        id: 'SignupForm.numberOfProductsRequiredMessage',
      });

      const numberOfProductsRequired = validators.required(numberOfProductsRequiredMessage);

      const offerSamplesLabel = intl.formatMessage({
        id: 'SignupForm.offerSamplesLabel',
      });
      const offerSamplesPlaceholder = intl.formatMessage({
        id: 'SignupForm.offerSamplesPlaceholder',
      });

      const offerSamplesRequiredMessage = intl.formatMessage({
        id: 'SignupForm.offerSamplesRequiredMessage',
      });

      const offerSamplesRequired = validators.required(offerSamplesRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );
      const productAgreementLink = (
        <ExternalLink className={css.termsLink} href={productAgreementPdfLink}>
          <FormattedMessage id="SignupForm.productAgreementText" />
        </ExternalLink>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <p className={css.signupWarning}>
            {isSellerSignup ? (
              <FormattedMessage
                id="SignupForm.vendorSignupText"
                values={{
                  buyerLink: (
                    <NamedLink name="BuyerSignupPage">
                      <FormattedMessage id="SignupForm.vendorSignupBuyerLink" />
                    </NamedLink>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id="SignupForm.buyerSignupText"
                values={{
                  sellerLink: (
                    <NamedLink name="SellerSignupPage">
                      <FormattedMessage id="SignupForm.buyerSignupVendorLink" />
                    </NamedLink>
                  ),
                }}
              />
            )}
          </p>

          <div>
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <FieldTextInput
              className={css.email}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            {isSellerSignup ? (
              <>
                <FieldTextInput
                  className={css.businessName}
                  type="text"
                  id={formId ? `${formId}.businessName` : 'businessName'}
                  name="businessName"
                  autoComplete="organization"
                  label={businessNameLabel}
                  placeholder={businessNamePlaceholder}
                  validate={businessNameRequired}
                />
                <FieldTextInput
                  className={css.address}
                  type="text"
                  id={formId ? `${formId}.addressLine1` : 'addressLine1'}
                  name="addressLine1"
                  autoComplete="address-line1"
                  label={addressLine1Label}
                  placeholder={addressLine1Placeholder}
                  validate={addressLine1Required}
                />
                <FieldTextInput
                  className={css.address}
                  type="text"
                  id={formId ? `${formId}.addressLine2` : 'addressLine2'}
                  name="addressLine2"
                  autoComplete="address-line2"
                  label={addressLine2Label}
                  // placeholder={addressLine2Placeholder}
                />
                <FieldTextInput
                  className={css.address}
                  type="text"
                  id={formId ? `${formId}.city` : 'city'}
                  name="city"
                  autoComplete="address-level2"
                  label={cityLabel}
                  placeholder={cityPlaceholder}
                  validate={cityRequired}
                />
                <div className={css.location}>
                  <FieldTextInput
                    className={css.locationRoot}
                    type="text"
                    id={formId ? `${formId}.postCode` : 'postCode'}
                    name="postCode"
                    autoComplete="postal-code"
                    label={postCodeLabel}
                    placeholder={postCodePlaceholder}
                    validate={postCodeRequired}
                  />
                  <FieldTextInput
                    className={css.locationRoot}
                    type="text"
                    id={formId ? `${formId}.country` : 'country'}
                    name="country"
                    autoComplete="country-name"
                    label={countryLabel}
                    placeholder={countryPlaceholder}
                    validate={countryRequired}
                  />
                </div>
                <FieldTextInput
                  className={css.website}
                  type="website"
                  id={formId ? `${formId}.website` : 'website'}
                  name="website"
                  autoComplete="website"
                  label={websiteLabel}
                  placeholder={websitePlaceholder}
                  validate={validators.composeValidators(websiteRequired, websiteLinkInvalid)}
                />
                <FieldTextInput
                  className={css.phoneNumber}
                  type="phoneNumber"
                  id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                  label={phoneNumberLabel}
                  placeholder={phoneNumberPlaceholder}
                />
                <FieldTextInput
                  className={css.minimumOrder}
                  type="number"
                  id={formId ? `${formId}.minimumOrder` : 'minimumOrder'}
                  name="minimumOrder"
                  label={minimumOrderLabel}
                  placeholder={minimumOrderPlaceholder}
                  validate={minimumOrderRequired}
                />
                <FieldTextInput
                  className={css.numberOfProducts}
                  type="number"
                  id={formId ? `${formId}.numberOfProducts` : 'numberOfProducts'}
                  name="numberOfProducts"
                  label={numberOfProductsLabel}
                  placeholder={numberOfProductsPlaceholder}
                  validate={numberOfProductsRequired}
                />
                <FieldSelect
                  className={css.offerSamples}
                  id={formId ? `${formId}.offerSamples` : 'offerSamples'}
                  name="offerSamples"
                  label={offerSamplesLabel}
                  placeholder={offerSamplesPlaceholder}
                  validate={offerSamplesRequired}
                >
                  <option value='' disabled>{offerSamplesPlaceholder}</option>
                  <option>Yes</option>
                  <option>No</option>
                </FieldSelect>
              </>
            ) : (
              <>

                <FieldTextInput
                  className={css.businessName}
                  type="buyerBusinessName"
                  id={formId ? `${formId}.buyerBusinessName` : 'buyerBusinessName'}
                  name="buyerBusinessName"
                  label={buyerBusinessNameLabel}
                  placeholder={businessNamePlaceholder}
                />
                <FieldTextInput
                  className={css.website}
                  type="website"
                  id={formId ? `${formId}.website` : 'website'}
                  name="website"
                  autoComplete="website"
                  label={websiteLabel}
                  placeholder={websitePlaceholder}
                  validate={websiteLinkInvalid}
                />
              </>
            )}

            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password2` : 'password2'}
              name="password2"
              autoComplete="new-password"
              label={confirmPasswordLabel}
              placeholder={confirmPasswordPlaceholder}
              validate={confirmPasswordValidator}
            />
          </div>

          {isSellerSignup ? (
            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperHighlightedText}>
                <ExternalLink
                  className={css.termsText}
                  href="https://docs.google.com/presentation/d/19GDfHRrrK7paxunoxOcVbsUkemGKq4Nc1JBlnhiFYPc/edit"
                >
                  <FormattedMessage id="SignupForm.vendorDeck" />
                </ExternalLink>
              </p>
              <p className={css.bottomWrapperText}>
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink, productAgreementLink }}
                  />
                </span>
              </p>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          ) : (
            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsBuyerAcceptText"
                    values={{ termsLink }}
                  />
                </span>
              </p>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          )}
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
